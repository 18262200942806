.slider:hover {
    cursor: ew-resize;
}

.selectedFeatureDetailsContainer {
    position: absolute;
    right: 7px;
    min-width: 200px;
    height: 250px;
    top: 150px;
    display: flex;
    overflow: visible;
}